.hero.post {
  position: relative;
  border-bottom: 5px solid $brand-orange;

  .photo {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    opacity: 0.4;
  }

  h2 {
    font-size: 54px;
    line-height: 72px;
    position: absolute;
    padding: 0 100px 20px;
    bottom: 40px;
    left: 0;
    color: $white;
    text-align: left;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 2px;
    box-sizing: border-box;

    @include breakpoint(max-width $iPad-portrait) {
      padding: 0;
      font-size: 32px;
      line-height: 48px;
      margin: 0 auto;
      width: 80%;
      left: 50px;
    }

    @include breakpoint(max-width $iPhone-portrait) {
      font-size: 24px;
      line-height: 32px;
    }

    small {
      display: block;
      padding: 15px 0 5px;
      font-size: 24px;
      line-height: 34px;
      color: $light;
    }
  }
}

main#post {
  background: $light;
  
  .post-content {
    padding: 50px 0 100px;
    margin: 0 100px;
    text-align: left;

    @include breakpoint(max-width $iPad-portrait) {
      margin: 0 50px;
    }

    h3 {
      font-size: 24px;
      line-height: 32px;
      padding: 40px 0 20px;
      font-weight: 600;
    }

    p {
      text-align: left;
      max-width: 100%;
      font-size: 16px;
      line-height: 24px;
      margin: 10px 0;
    }

    ul {
      list-style-type: square;
      list-style-position: inside;
      color: $dark;
      margin-left: 20px;
      padding: 10px 0;

      li {
        font-size: 14px;
        line-height: 20px;
        margin: 10px 0;
        color: $dark-gray;
      }
    }
  }
}
